import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { TEMPI_DI_CONSEGNA_POPUP } from '../TempiDiConsegnaPopup/TempiDiConsegnaPopup.config';
import TempiDiConsegnaBanner from './TempiDiConsegnaBanner.component';

/** @namespace Pwa/Component/TempiDiConsegnaBanner/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({

});

/** @namespace Pwa/Component/TempiDiConsegnaBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(TEMPI_DI_CONSEGNA_POPUP, payload)),
});

/** @namespace Pwa/Component/TempiDiConsegnaBanner/Container */
export class TempiDiConsegnaBannerContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
    };

    containerFunctions = {
        fnMostraPopup: this.fnMostraPopup.bind(this),
    };

    fnMostraPopup() {
        const { showPopup } = this.props;
        showPopup();
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        return (
            <TempiDiConsegnaBanner
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TempiDiConsegnaBannerContainer);
