import { connect } from 'react-redux';

import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import {
    FooterContainer as SourceFooterContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Footer/Footer.container';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { showPopup } from 'Store/Popup/Popup.action';

import Footer from './Footer.component';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState:
    state.NavigationReducer[NavigationType.TOP_NAVIGATION_TYPE].navigationState,
    pageTitle: state.MetaReducer.title,
    state,
});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (payload) => dispatch(showPopup(LEGAL_NOTES_POPUP, payload)),
});

/** @namespace Pwa/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    __construct(props) {
        super.__construct?.(props);

        this.state = {
            isVisible: true,
            reRenderCount: 0,
        };
    }

    componentDidUpdate(prevProps) {
        const { pageTitle } = this.props;
        const { pageTitle: prevPageTitle } = prevProps;

        if (pageTitle !== prevPageTitle) {
            this.setState({ reRenderCount: this.state.reRenderCount + 1 });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        showFooter: () => this.setState({ isVisible: true }),
        hideFooter: () => this.setState({ isVisible: false }),
    };

    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
            ...this.state,
        };
    }

    render() {
        return <Footer { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
