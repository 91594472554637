import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import CmsPopup from 'Component/CmsPopup';
import ContentWrapper from 'Component/ContentWrapper';
import { Page } from 'Component/Header/Header.config';
import Image from 'Component/Image';
import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import NewsletterCustom from 'Component/NewsletterCustom';
import ScrollToTop from 'Component/ScrollToTop';
import TempiDiConsegnaBanner from 'Component/TempiDiConsegnaBanner';
import { FooterComponent as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import RenderWhenVisibleComponent from 'SourceComponent/RenderWhenVisible';

import './Footer.override.style';

export const HIDE_FOOTER_STATES_DESKTOP = [];

export const HIDE_FOOTER_STATES_MOBILE = [
    Page.MENU,
    Page.CART,
    Page.MENU_SUBCATEGORY,
];

/** @namespace Pwa/Component/Footer/Component */
export class FooterComponent extends SourceFooter {
    static propTypes = {
        ...SourceFooter.propTypes,
        navigationState: PropTypes.object.isRequired,
    };

    shouldComponentUpdate(nextProps) {
        const {
            device: { isMobile },
            isVisibleOnMobile,
            copyright,
            newsletterActive,
            isVisible,
            pageTitle,
        } = this.props;

        const {
            device: { isMobile: nextIsMobile },
            isVisibleOnMobile: nextIsVisibleOnMobile,
            copyright: nextCopyright,
            newsletterActive: nextNewsletterActive,
            isVisible: nextIsVisible,
            pageTitle: nextPageTitle,
        } = nextProps;

        return (
            isMobile !== nextIsMobile
      || isVisibleOnMobile !== nextIsVisibleOnMobile
      || copyright !== nextCopyright
      || newsletterActive !== nextNewsletterActive
      || isVisible !== nextIsVisible
      || pageTitle !== nextPageTitle
        );
    }

    shouldIncludeFooter(navigationStateName) {
        const { device, showFooter, hideFooter } = this.props;
        const statesConfig = device.isMobile
            ? HIDE_FOOTER_STATES_MOBILE
            : HIDE_FOOTER_STATES_DESKTOP;

        if (!statesConfig.includes(navigationStateName)) {
            showFooter();
        } else {
            hideFooter();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            navigationState: { name: prevName },
        } = prevProps;
        const {
            navigationState: { name },
        } = this.props;

        if (prevName !== name) {
            this.shouldIncludeFooter(name);
        }
    }

    componentDidMount() {
        const self = this;
        const {
            navigationState: { name },
        } = self.props;
        self.shouldIncludeFooter(name);

        // CUSTOM EVENT
        document.addEventListener('UPDATE_NAVIGATION_AFTER', async () => {
            const {
                navigationState: { name },
            } = self.props;
            self.shouldIncludeFooter(name);
        });
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div block="Footer" elem="Columns" mix={ { block: 'ContentWrapper' } }>
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    renderAltro() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'AltroContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'AltroContent' } }
              label=""
            >
                <CmsBlock identifier="footer-altro" />
            </ContentWrapper>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;
        const imgSrc = '/media/wysiwyg/loghi/logo-light.png';

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <Image
                  src={ imgSrc }
                  mix={ {
                      block: 'Footer',
                      elem: 'Logo',
                  } }
                />
                <span block="Footer" elem="Copyright">
                    ©MondoConvenienza
                        <br />
                        { copyright }
                        |&nbsp;
                        { this.renderShareWishlistButton() }
                </span>
            </ContentWrapper>
        );
    }

    renderShareWishlistButton() {
        const { showPopup } = this.props;

        return (
            <span
              block="Footer"
              elem="LegalNotes"
              role="button"
              tabIndex="0"
              onClick={ showPopup }
            >
                { __('Company data') }
            </span>
        );
    }

    renderNewsletterPanel() {
        if (window.location.pathname === '/trasporto-e-montaggio') {
            return null;
        }

        if (window.location.pathname === '/promozione-camerette-coupon') {
            return null;
        }

        if (window.location.pathname === '/promozione-camere-coupon') {
            return null;
        }

        if (window.location.pathname === '/lp-mcday2022') {
            return null;
        }

        if (window.location.pathname === '/super-saldi-arredamento') {
            return null;
        }

        if (window.location.pathname === '/dolce-casa') {
            return null;
        }

        return <NewsletterCustom />;
    }

    renderLegalNotesPopup() {
        const legal_notes = 'legal_notes';

        return (
            <CmsPopup
              id={ LEGAL_NOTES_POPUP }
              cms_block={ legal_notes }
              blockType="Legal"
            />
        );
    }

    renderTempiDiConsegnaBanner() {
        return <TempiDiConsegnaBanner />;
    }

    render() {
        // const { isVisibleOnMobile, device } = this.props;
        //
        // if (!isVisibleOnMobile && device.isMobile) {
        //     return null;
        // }
        //
        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        const { isVisible } = this.props;

        if (window.location.pathname === '/concorsocatanzaro') {
            return null;
        }

        return (

            isVisible && (
                <RenderWhenVisibleComponent>
                <footer block="Footer" aria-label="Footer">
                { this.renderNewsletterPanel() }
                { this.renderContent() }
                { this.renderAltro() }
                { this.renderCopyrightContent() }
                { this.renderLegalNotesPopup() }
                <ScrollToTop />
                </footer>
                </RenderWhenVisibleComponent>

            )
        );
    }
}

export default FooterComponent;
