import { PureComponent } from 'react';

import ScrollToTop from './ScrollToTop.component';

/** @namespace Pwa/Component/ScrollToTop/Container */
export class ScrollToTopContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        scrollToTop: this.scrollToTop.bind(this),
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            isVisible: false,
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const PAGE_OFFESET = 300;

            if (window.pageYOffset > PAGE_OFFESET) {
                this.setState({
                    isVisible: true,
                });
            } else {
                this.setState({
                    isVisible: false,
                });
            }
        });
    }

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    render() {
        const { isVisible } = this.state;

        return (
            <ScrollToTop
              { ...this.containerFunctions }
              { ...this.containerProps() }
              isVisible={ isVisible }
            />
        );
    }
}

export default ScrollToTopContainer;
