import Form from 'Component/Form';
import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscription,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.override.style';

/** @namespace Pwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    get fieldMap() {
        const fields = super.fieldMap;

        return {
            is_subscribed: {
                type: 'checkbox',
                label: __('GDPR_String_Newsletter'),
                value: 'allow_newletter_gdpr',
                checked: true,
            },
            ...fields,
        };
    }

    render() {
        const { isLoading, onFormSubmit } = this.props;

        return (
            <div block="NewsletterSubscription">
                <div block="NewsletterSubscription-Content">
                    <Form
                      onSubmit={ onFormSubmit }
                      mix={ { block: 'FieldForm' } }
                    >
                        <strong>Resta informato su novità e promozioni</strong>
                        <p>
                            Registrati alle newsletter Mondo Convenienza per non perdere
                            gli sconti e conoscere le offerte dedicate sui nostri prodotti

                        </p>
                        <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                            { this.renderFields() }
                            { this.renderActions() }
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
